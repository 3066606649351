<template>
  <Accordion class="bg-bluegray-300 break-words" @deleteItem="openPopUp" :readOnly="readOnly" :id="id" :preview="preview">
    <template v-slot:title>
      <div class="w-full">
        <p class="text-lg font-semibold float-left">{{item.source}}</p>
      </div>
    </template>
    <template v-slot:content>
      <form class="mt-4" @submit.prevent="updateLiteratureSearchItem">
        <label class="text-sm" for="source">{{$t('schema.literatureSearch.label.source')}}:</label>
        <input v-if="model.editableSource" type="text" id="source" v-model="updateItem.source" class="bg-white" v-bind:readonly="!isEdit" />
        <input v-if="!model.editableSource" type="text" id="source" v-model="updateItem.source" class="bg-white" disabled />
        <div v-show="model.searchType === 'Web'">
          <label class="text-sm" for="link">{{$t('schema.literatureSearch.label.sourceLink')}}:</label>
          <input type="url" id="link" pattern="https?://.+" v-model="updateItem.url" v-bind:readonly="!isEdit" />
        </div>
        <label class="text-sm" for="strategy">{{$t('schema.literatureSearch.label.searchStrategy')}}:</label>
        <div v-if="!isEdit" v-html="item.strategy" class="bg-white p-3 border-2 rounded mb-4 xhtml-string">
        </div>
        <div v-else>
          <Editor v-model='updateItem.strategy'
                  :api-key='getTinyKey'
                  :init='getTinyConfig' />
        </div>
        <div v-show="model.searchType === 'DB'" class="lg:w-1/4 sm:w-1/2">
          <label class="text-sm" for="hits">{{$t('schema.literatureSearch.label.numberOfHits')}}:</label>
          <input type="number" id="hits" v-model="updateItem.numberOfSearchHits" v-bind:readonly="!isEdit" />
        </div>
        <div class="lg:w-1/4 sm:w-1/2">
          <label class="text-sm" for="date">{{$t('schema.common.time.date')}}:</label>
          <input type="date" placeholder="dd-mm-åååå" min="01-01-2022" id="date" v-model="updateItem.date" v-bind:readonly="!isEdit" />
        </div>
        <div class="flex">
          <div v-if="!readOnly">
            <button v-show="!isEdit" @click.prevent="setEditable" class="mr-4 btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="pen" class="mr-2" />
              {{$t('schema.common.action.edit')}}
            </button>
            <button v-show="isEdit"
                    type="submit"
                    class="mr-4 btn btn--small btn--charcoalgray"
                    :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                    :disabled="isSaving">
              <font-awesome-icon v-show="!isSaving" icon="save" class="mr-2" />
              <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2" />
              {{$t('schema.common.action.update')}}
            </button>
            <button v-show="isEdit" type="reset" @click.prevent="cancel" class="btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="ban" class="mr-2" />
              {{$t('schema.common.action.cancel')}}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Accordion>
  <div v-if="showPopUp">
    <ConfirmPopUp @close="closePopUp" @confirm="deleteLiteratureSearchItem" :text="'Ønsker du å slette oppføringen?'" />
  </div>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import TinyConfig from '@/assets/scripts/tinyConfig';
  import Accordion from '@/views/components/accordion/Accordion.vue';
  import ConfirmPopUp from '@/views/components/confirm/ConfirmPopUp.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      Accordion,
      ConfirmPopUp,
      Editor,
    },
    computed: {
      getTinyConfig() {
        return TinyConfig.getConfig(this.type);
      },
      getTinyKey() {
        return TinyConfig.getAPIKey();
      },
    },
    data() {
      return {
        id: this.$uuid(),
        showPopUp: false,
        item: this.model,
        isEdit: false,
        isSaving: false,
        updateItem: {
          strategy: this.model.strategy,
          numberOfSearchHits: this.model.numberOfSearchHits,
          date: this.model.date,
          source: this.model.source,
          url: this.model.sourceLink,
        },
      };
    },
    methods: {
      goto() {
        const el = document.getElementById(this.id);
        el.scrollIntoView({ behavior: 'smooth' });
      },
      getSchema() {
        this.$emit('getSchema');
      },
      onEditorUpdate(input) {
        this.updateItem.strategy = input;
      },
      setEditable() {
        this.isEdit = true;
        this.goto();
      },
      cancel() {
        this.isEdit = false;
        this.goto();
      },
      updateLiteratureSearchItem() {
        this.isSaving = true;

        const htmlEncodedStrategy = this.htmlEncode(this.updateItem.strategy);

        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.id,
            date: new Date(this.updateItem.date),
            strategy: htmlEncodedStrategy,
            numberOfSearchHits: this.updateItem.numberOfSearchHits,
            source: this.updateItem.source,
            url: this.updateItem.url,
          }),
        };

        console.log('Oppdaterer litteratursøk');
        console.log(requestOptions);

        fetch('/api/schema/UpdateLiteratureSearchItem', requestOptions)
          .then((response) => {
            console.log('Får respons fra /api/schema/UpdateLiteratureSearchItem, responsen er: ');
            console.log(response);

            if (response.ok) {
              console.log('Oppdatering var en suksess!');
              console.log(response.status);
              console.log(response.statusText);

              setTimeout(() => {
                this.isEdit = false;
                this.goto();
                this.$emit('getSchema');
                this.isSaving = false;
                this.$toast.open({
                  message: 'Litteratursøk oppdatert',
                  type: 'success',
                  dismissible: true,
                });
              }, 500);
            } else {
              console.log('Oppdatering feilet med statuskode: ');
              console.log(response.status);
              console.log(response.statusText);
              setTimeout(() => {
                this.isSaving = false;
                this.$toast.open({
                  message: 'Litteratursøk ikke oppdatert',
                  type: 'error',
                  dismissible: true,
                });
              }, 500);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit('getSchema');
          });
      },
      openPopUp() {
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
      deleteLiteratureSearchItem() {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.id,
            ownerId: this.model.ownerId,
            section: this.model.section,
          }),
        };
        fetch(`${window.location.origin}/api/schema/DeleteLiteratureSearchItem`, requestOptions)
          .then(() => {
            this.$emit('getSchema');
            this.$toast.open({
              message: 'Litteratursøk slettet',
              type: 'success',
              dismissible: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.$emit('getSchema');
          });
      },
      htmlEncode(str) {
        const div = document.createElement('div');
        div.appendChild(document.createTextNode(str));
        return div.innerHTML;
      },
    },
    emits: ['getSchema'],
  };
</script>
