<template>
  <div class=" flex justify-center items-center">
    <div v-if="this.small" class="loader ease-linear rounded-full border-8 border-t-8 border-gray-100 h-10 w-10"></div>
    <div v-else class="loader ease-linear rounded-full border-8 border-t-8 border-gray-100 h-32 w-32"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    small: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
  .loader {
    border-top-color: rgb(57, 60, 97);
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
