<template>
  <div class="flex-row md:flex print:hidden m-0 md:space-x-4">
    <!-- kommentert ut frem til det er blitt implementert notifikasjonsløsing i fhi 2.0
    <button
      v-if="showNotify"
      @click="showPopup = !showPopup"
      class="flex items-center text-sm cursor-pointer text-lightblue-300 group"
      title="Få varsel om endringer"
    >
      <font-awesome-icon class="mr-2 " icon="bell"/>
      <span class="text-left underline group-hover:no-underline">Få varsel om endringer</span>
    </button> -->
    <EpiLink
      v-if="model.contactEmail && !hideContact"
      :url="`mailto:${model.contactEmail}?subject=${model.heading}`"
      class="items-center text-sm cursor-pointer md:flex text-lightblue-300 group"
      target="_blank"
      title="Send epost"
    >
      <font-awesome-icon class="mr-2" icon="envelope"/>
      <span class="text-left underline group-hover:no-underline">Kontakt forslagsstiller ({{model.contactName}})</span>
    </EpiLink>
    <div
      v-if="showSharing"
      class="relative md:block mt-4 md:mt-0"
    >
      <button
        @click.prevent="showSharingDropdown = !showSharingDropdown"
        class="flex items-center text-sm cursor-pointer text-lightblue-300 group"
      >
        <font-awesome-icon class="mr-2 " icon="link"/>
        <span class="text-left underline group-hover:no-underline">Del med andre</span>
      </button>

      <div
        v-if="showSharingDropdown"
        v-click-outside="closeSharingDropdown"
        class="absolute left-0 w-full p-3 text-sm bg-bluegray-100 top-6"
      >
        <ul class="p-0 m-0 list-none">
          <li class="m-0 mb-2 md:mb-0.5 list-none">
            <EpiLink
              :url="`mailto:?subject=${model.heading ?? model.name}&amp;body=${this.url}`"
              target="_blank"
              title="Del metodevarsel via epost (åpnes i nytt vindu)"
              class="text-lightblue-300 group"
            >
              <font-awesome-icon class="mr-2" icon="envelope" />
              <span class="underline group-hover:no-underline">E-post</span>
            </EpiLink>
          </li>
          <li class="m-0 mb-2 md:mb-0.5 list-none">
            <EpiLink
              :url="`https://www.facebook.com/sharer/sharer.php?u=${this.url}&t=${model.heading ?? model.name}`"
              target="_blank"
              title="Del metodevarsel på Facebook (åpnes i nytt vindu)"
              class="text-lightblue-300 group"
            >
              <font-awesome-icon :icon="['fab', 'facebook']" class="mr-2" />
              <span class="underline group-hover:no-underline">Facebook</span>
            </EpiLink>
          </li>
          <li class="m-0 mb-2 md:mb-0.5 list-none">
            <EpiLink
              :url="`https://twitter.com/share?url=${this.url}&text=${model.heading ?? model.name}`"
              target="_blank"
              title="Del metodevarsel på Twitter (åpnes i nytt vindu)"
              class="text-lightblue-300 group"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" class="mr-2" />
              <span class="underline group-hover:no-underline">Twitter</span>
            </EpiLink>
          </li>
        </ul>
      </div>
    </div>
    <EpiLink v-if="showPrintableVersionLink"
             :url="this.model.printPreviewUrl"
             target="_blank"
             title="Til utskriftsvennlig versjon (åpnes i nytt vindu)"
             class="hidden md:block flex items-center text-sm cursor-pointer text-lightblue-300 group"
             >
      <font-awesome-icon class="mr-2 " icon="print" />
      <span class="text-left underline group-hover:no-underline">{{$t('toolbar.print')}}</span>
    </EpiLink>
    <button v-if="showPrint" class="flex items-center text-sm cursor-pointer text-lightblue-300 group hidden md:block" onclick="window.print()">
      <font-awesome-icon class="mr-2 " icon="print" />
      <span class="text-left underline group-hover:no-underline">Skriv ut</span>
    </button>
    <Popup v-if="showPopup" @close="showPopup = false" :topic="model.heading" />
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import EpiLink from '@/components/EpiLink.vue';
import Popup from '@/views/components/popup/popup.vue';

export default {
  components: {
    EpiLink,
    Popup,
  },
  data() {
    return {
      showSharingDropdown: false,
      showPopup: false,
      url: window.location.href,
    };
  },
  props: {
    methodToolbar: {
      type: Boolean,
    },
    model: {
      type: Object,
      required: true,
    },
    showNotify: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showSharing: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showPrintableVersionLink: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showPrint: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideContact: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    closeSharingDropdown() {
      this.showSharingDropdown = false;
    },
  },
};
</script>
