<!--New peer review version with support for multiple reviews-->
<template>
  <div :id="model.contentGuid" class="flex flex-col mb-12" :class="!this.preview ? 'w-3/4' : ''">
    <div class="flex items-center pb-3 mb-6 border-b border-gray-100">
      <h2 class="font-serif font-semibold text-hmd" v-if="this.reviews && this.reviews.length > 1">
        Fagfellevurderinger
      </h2>
      <h2 class="font-serif font-semibold text-hmd" v-else>
        Fagfellevurdering
      </h2>
    </div>

    <!--Not reviewed-->
    <div v-if="!isPeerReviewed" class="w-3/4 bg-orange-100 rounded py-2 px-4 opacity-80">
      {{ $t('schema.schemaPage.peerReview.notPeerReviewed') }}
    </div>

    <!--Name list-->
    <div v-if="displayNameList">
      <ul>
        <li v-for="schemaItem in this.reviews" :key="schemaItem.name">
          <div v-if="!displayReview(schemaItem.contributorId) && !displayForInitiator(schemaItem)" class="border-2 my-2 p-4 rounded">
            <h3 class="text-xl font-medium">{{ schemaItem.name }}</h3>
          </div>
        </li>
      </ul>
    </div>

    <div>
      <!--Single review-->
      <div v-if="this.reviews && this.reviews.length > 0 && this.reviews.length < 2">
        <div v-if="displayReview(this.reviews[0].contributorId) || displayForInitiator(this.reviews[0])">
          <!-- Messages -->
          <div v-if="!readOnly || displayForInitiator(this.reviews[0])">
            <div class="w-3/4 mb-4">
              <div v-if="this.reviews[0].isReviewed" class="bg-orange-100 rounded py-2 px-4 opacity-80">
                {{ $t('schema.schemaPage.peerReview.sent') }}
              </div>
            </div>
          </div>
          <ul class="mt-6">
            <li v-for="item in this.reviews[0].schemaItems" :key="item.contentGuid">
              <Text v-if="item.blockType?.toString().toLowerCase() === 'text' || item.blockType?.toString().toLowerCase()  === '5'"
                    :pageId="pageId"
                    :model="item"
                    :readOnly="readOnly"
                    :preview="preview"
                    :isPublished="isPublished"
                    @on-save="saveInput"/>
              <Section v-if="item.blockType?.toString().toLowerCase()  === 'section' || item.blockType?.toString().toLowerCase()  === '0'"
                       :pageId="pageId"
                       :model="item.properties.sectionItem"
                       @on-save="saveInput"
                       :preview="preview"
                       :readOnly="readOnly"
                       :isPublished="isPublished"/>
            </li>
          </ul>
          <!-- Messages -->
          <div v-if="!readOnly">
            <div class="w-3/4 mb-4">
              <div v-if="this.reviews[0].isReviewed" class="bg-orange-100 rounded py-2 px-4 opacity-80">
                {{ $t('schema.schemaPage.peerReview.sent') }}
              </div>
            </div>
          </div>
          <!-- Buttons -->
          <div v-if="!readOnly" class="w-3/4">
            <button @click.prevent="openPopUp"
                    class="btn btn--small btn--charcoalgray float-right"
                    :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                    :disabled="isSaving">
              <font-awesome-icon v-show="!isSaving" icon="long-arrow-alt-right" class="mr-2"/>
              <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2"/>
              {{ $t('schema.schemaPage.peerReview.send') }}
            </button>
          </div>
        </div>
      </div>

      <!--Multiple reviews - accordion-->
      <div v-if="this.reviews && this.reviews.length > 1">
        <ul>
          <li v-for="schemaItem in this.reviews" :key="schemaItem.contentGuid">
            <Accordion :readOnly="readOnly" :preview="preview" :id="id" :hideDelete="true" v-if="displayReview(schemaItem.contributorId) || displayForInitiator(schemaItem)">
              <template v-slot:title>
                <div class="w-full">
                  <h3 class="text-xl font-medium">{{ schemaItem.name }}</h3>
                </div>
              </template>
              <template v-slot:content>
                <div class="w-3/4 mb-4">
                  <!--Messages-->
                  <div v-if="!readOnly || displayForInitiator(schemaItem)">
                    <div v-if="schemaItem.isReviewed" class="bg-orange-100 rounded py-2 px-4 opacity-80">
                      {{ $t('schema.schemaPage.peerReview.sent') }}
                    </div>
                    <div v-if="!schemaItem.isReviewed && !isPeerReview" class="bg-orange-100 rounded py-2 px-4 opacity-80">
                      {{ $t('schema.schemaPage.peerReview.reviewNotSent') }}
                    </div>
                  </div>
                </div>
                <ul class="mt-6">
                  <li v-for="item in schemaItem.schemaItems" :key="item.contentGuid">
                    <Text v-if="item.blockType?.toString().toLowerCase() === 'text' || item.blockType?.toString().toLowerCase()  === '5'"
                          :pageId="pageId"
                          :model="item"
                          :readOnly="readOnly"
                          :preview="preview"
                          :isPublished="isPublished"
                          @on-save="saveInput"/>
                    <Section v-if="item.blockType?.toString().toLowerCase()  === 'section' || item.blockType?.toString().toLowerCase()  === '0'"
                             :pageId="pageId"
                             :model="item.properties.sectionItem"
                             @on-save="saveInput"
                             :preview="preview"
                             :readOnly="readOnly"
                             :isPublished="isPublished"/>
                  </li>
                </ul>
                <!--Messages-->
                <div v-if="!readOnly && schemaItem.isReviewed" class="bg-orange-100 rounded py-2 px-4 mb-2 opacity-80">
                  {{ $t('schema.schemaPage.peerReview.sent') }}
                </div>
                <!-- Buttons -->
                <div v-if="!readOnly" class="w-full flex justify-end">
                  <button @click.prevent="openPopUp"
                          class="btn btn--small btn--charcoalgray"
                          :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                          :disabled="isSaving">
                    <font-awesome-icon v-show="!isSaving" icon="long-arrow-alt-right" class="mr-2"/>
                    <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2"/>
                    {{ $t('schema.schemaPage.peerReview.send') }}
                  </button>
                </div>
              </template>
            </Accordion>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="showPopUp">
      <ConfirmPopUp @close="closePopUp" @confirm="send" :text="$t('schema.schemaPage.peerReview.sendConfirm')"/>
    </div>
  </div>
</template>

<script>
import ConfirmPopUp from '@/views/components/confirm/ConfirmPopUp.vue';
import Section from '@/views/components/schema/Section.vue';
import Text from '@/views/components/schema/Text.vue';
import Accordion from '@/views/components/accordion/Accordion.vue';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    reviews: {
      type: Object,
      required: true,
    },
    isPeerReviewed: {
      type: Boolean,
      required: true,
    },
    pageId: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    preview: {
      type: Boolean,
      required: false,
    },
    isPublished: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      reviewItems: [],
      id: this.$uuid(),
      contributorId: '',
      role: '',
      showPopUp: false,
      isSaving: false,
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.contributorId = params.has('id') ? params.get('id') : '';
    this.role = params.has('role') ? params.get('role').toLowerCase() : '';
  },
  methods: {
    openPopUp() {
      this.showPopUp = true;
    },
    closePopUp() {
      this.showPopUp = false;
    },
    saveInput() {
      this.$emit('onSave');
    },
    send() {
      this.isSaving = true;
      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          assessmentId: this.pageId,
          isPeerReviewed: true,
          contributorId: this.contributorId,
        }),
      };
      fetch(`${window.location.origin}/api/schema/SetPeerReviewStatus`, requestOptions)
        .then(() => {
          this.$emit('update');
          this.$toast.open({
            message: 'Melding om ferdigstilt fagfellevurdering er sendt inn',
            type: 'success',
            dismissible: true,
          });
          this.isSaving = false;
          // Update status for item
          const item = this.reviews.find((i) => i.contributorId === this.contributorId);
          if (item) {
            // Oppdater verdien direkte
            item.isReviewed = true;
          }
        });
    },
    displayReview(id) {
      let display = false;
      console.log('published', this.isPublished);
      if (this.isPublished) {
        display = true;
      } else if ((this.role === 'peer' && id === this.contributorId)) {
        display = true;
      }
      return display;
    },
    displayForInitiator(review) {
      if (this.role === 'initiator' && review.isReviewed) {
        return true;
      }
      return false;
    },
    displayNameList() {
      let display = false;
      if (this.isPublished) {
        display = false;
      } else if (this.preview) {
        display = true;
      }
      return display;
    },
  },
  components: {
    ConfirmPopUp,
    Section,
    Text,
    Accordion,
  },
};
</script>
