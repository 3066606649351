<template>
  <div class="container">
    <div v-if="model.isDefinition">
      {{ $t('schema.schemaPage.onlyEditableInEditorMode') }}
    </div>
    <div v-else class="w-full">
      <!-- Header -->
      <div class="flex justify-end mb-8">
        <div class="flex w-full xl:w-3/4">
          <div class="w-full xl:w-3/4">
            <EditableHeading :text="model.name"
                             :requireCallbackAfterSave="true"
                             :readOnly="peerReview || isPublished || isPendingPublish"
                             @changed="updateSchemeTitle" />
            <div class="flex flex-row">
              <StatusBadge :status="assessmentStatus" />
              <div class="ml-2">
                <RoleBadge :userRole="userRole" :assessmentType="assessmentType" />
              </div>
              <p class="py-1 px-2 text-sm text-gray-300">
                {{ $t('schema.common.save.lastSaved')}}: {{lastSaved}}
              </p>
            </div>

            <div v-if="model.document !== null && model.document.url !== null"
                 class="m-4">
              <EPiLink :url="model.document.url">
                <font-awesome-icon class="float-left mr-4 text-xl" icon="file-pdf" />
                <span class="text-lg">
                  {{ $t('schema.schemaPage.downloadMethodEvaluation') }}
                </span>
              </EPiLink>
            </div>

            <div class="flex flex-wrap my-4">
              <div v-if="schema.organization"
                   class="w-1/2">
                <p class="py-1 text-sm text-gray-300">
                  <span v-if="schema.assessmentType === '2' || schema.assessmentType === '3'">
                    {{$t('schema.schemaPage.roles.municipality')}}
                  </span>
                  <span v-else>
                    {{$t('schema.schemaPage.roles.organization')}}
                  </span>
                </p>
                <p class="text-base mr-4">
                  {{organizationList}}
                </p>
              </div>
              <div v-if="schema.initiator"
                   class="w-1/2">
                <p class="py-1 text-sm text-gray-300">
                  <span v-if="schema.assessmentType === '2' || schema.assessmentType === '3'">
                    {{$t('schema.schemaPage.roles.initiator')}}
                  </span>
                  <span v-else>
                    {{$t('schema.schemaPage.roles.initiator')}}
                  </span>
                </p>
                <p class="text-base">
                  {{schema.initiator}}
                </p>
              </div>
              <div v-show="!model.isPublished" class="flex flex-row space-x-5 w-full mt-6">
                <!--Invite contributors-->
                <Invite v-if="this.userRole === 'initiator'"
                        :roles="model.schema.contributorRoles"
                        :contributors="contributors"
                        :pageId="model.schema.contentGuid"
                        :organizations="model.organizations"
                        :municipalityOptions="model.municipalityOptions"
                        :organizationOptions="model.organizationOptions"
                        :assessmentType="model.assessmentType"
                        @update="getSchema" />
                  <!--Contact us-->
                  <button class="flex items-center text-sm cursor-pointer text-lightblue-300 group">
                    <a :href="contactAddress">
                      <font-awesome-icon class="mr-1" icon="envelope" />
                      <span class="text-left underline group-hover:no-underline">Kontakt oss</span>
                    </a>
                  </button>
                  <!--Preview-->
                  <PreviewLink :url="model.previewUrl" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- tabs -->
      <div class="mt-8 border-b border-gray-100 tabwrapper mb-14">
        <div class="container flex justify-start xl:justify-end">
          <nav class="w-full pr-0 xl:w-3/4 lg:pr-4" aria-label="Faner">
            <ul class="flex w-full xl:w-3/4 -mb-px list-none">
              <li class="w-full"
                  v-for="(tab, index) in tabs"
                  :key="`${tab.value}`">
                <button @click.prevent="toggleTabs(index)"
                        :class="openTab === index ? 'bg-bluegray-200' : ''"
                        class="block w-full pb-1 text-base text-black cursor-pointer rounded-t-md border border-bluegray-600 py-4 hover:border-red-300 hover:text-red-300 hover:border-b-4">
                  {{tab.text}}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- // tabs -->
      <!-- Content -->
      <div class="flex">
        <aside class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
          <!-- Menu -->
          <SectionMenu :sections="sectionMenuItems" :openSection="openSection" @toggleSections="toggleSections($event)" />
        </aside>
        <div class="w-full pr-0 xl:w-3/4 lg:pr-4">
          <ul>
            <li v-for="schemaItem in schema.schemaItems" :key="schemaItem.contentGuid">
              <template v-if="schemaItem.fieldGroup === openTab && ( schemaItem.blockType.toString().toLowerCase() === 'section' || schemaItem.blockType.toString().toLowerCase() === '0' )" >
                <Section v-if="schemaItem.contentGuid === openSection"
                         :model="schemaItem"
                         :pageId="model.schema.contentGuid"
                         :readOnly="peerReview || isPublished || isPendingPublish"
                         :isPublished="isPublished"
                         @on-save="onInputSave"
                         @get-schema="getSchema" />
              </template>
              <template v-if="schemaItem.fieldGroup === openTab && ( schemaItem.blockType.toString().toLowerCase() === 'publishsection' || schemaItem.blockType.toString().toLowerCase() === '2' )">
                <Publish v-if="schemaItem.contentGuid === openSection"
                         :model="schemaItem"
                         :pageId="model.schema.contentGuid"
                         :readOnly="peerReview || isPublished || isPendingPublish"
                         :userRole="userRole"
                         :status="assessmentStatus"
                         @update="getSchema" />
              </template>
              <template v-if="schemaItem.fieldGroup === openTab && ( schemaItem.blockType.toString().toLowerCase() === 'peerreviewsection' || schemaItem.blockType.toString().toLowerCase() === '1' ) ">
                <PeerReviewSection v-if="schemaItem.contentGuid === openSection && !hasPeerReviewsV2"
                                   :model="schemaItem"
                                   :pageId="model.schema.contentGuid"
                                   :readOnly="isPublished || !peerReview || isPendingPublish"
                                   :title="model.name"
                                   :organization="schema.organization"
                                   :isPublished="isPublished"
                                   :isPeerReviewed="isPeerReviewed"
                                   :isPeerReview="peerReview"
                                   @on-save="onInputSave"
                                   @update="getSchema" />
                <PeerReviewSectionV2 v-if="schemaItem.contentGuid === openSection && hasPeerReviewsV2"
                                   :model="model"
                                   :pageId="model.schema.contentGuid"
                                   :reviews="model.schema.peerreviews"
                                   :isPeerReviewed="isPeerReviewed"
                                   :readOnly="isPublished || !peerReview || isPendingPublish"
                                   :title="model.name"
                                   :organization="schema.organization"
                                   :isPublished="isPublished"
                                   :isPeerReview="peerReview"
                                   @on-save="onInputSave"
                                   @update="getSchema" />
              </template>
            </li>
          </ul>
          <SectionNavigation v-if="sectionMenuItems"
                             :openSection="openSection"
                             :sections="sectionMenuItems"
                             @toggleSections="toggleSections($event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StatusBadge from '@/views/components/schema/badge/StatusBadge.vue';
  import RoleBadge from '@/views/components/schema/badge/RoleBadge.vue';
  import EPiLink from '@/components/EpiLink.vue';
  import Section from '@/views/components/schema/Section.vue';
  import SectionMenu from '@/views/components/schema/SectionMenu.vue';
  import SectionNavigation from '@/views/components/schema/SectionNavigation.vue';
  import PeerReviewSection from '@/views/components/schema/peerReview/PeerReviewSection.vue';
  import PeerReviewSectionV2 from '@/views/components/schema/peerReview/PeerReviewSectionV2.vue';
  import Invite from '@/views/components/schema/contributor/Invite.vue';
  import PreviewLink from '@/views/components/schema/preview/PreviewLink.vue';
  import Publish from '@/views/components/schema/publish/Publish.vue';

  export default {
    props: ['model'],
    components: {
      StatusBadge,
      RoleBadge,
      EPiLink,
      Section,
      SectionMenu,
      SectionNavigation,
      PeerReviewSection,
      PeerReviewSectionV2,
      Invite,
      PreviewLink,
      Publish,
    },
    data() {
      return {
        assessmentStatus: 1,
        isPendingPublish: false,
        isPublished: false,
        filteredSchemaItems: [],
        lastSaved: new Date(this.model.changed).toLocaleString(),
        openTab: 0,
        openSection: '',
        schema: this.model.schema,
        contactAddress: '',
        sectionMenuItems: [],
        userRole: '',
        userId: '',
        tabs: [],
        peerReview: false,
        isPeerReviewed: false,
        hasPeerReviewsV2: false,
        contributors: [],
        organizationList: this.model.schema.organizations.join(', '),
        assessmentType: this.model.schema.assessmentType,
      };
    },
    computed: {
      console: () => console,
    },
    mounted() {
      const params = new URLSearchParams(window.location.search);
      this.userRole = params.has('role') ? params.get('role').toLowerCase() : '';
      this.userId = params.has('id') ? params.get('id') : '';
      this.setupTabs();
      this.openTab = params.has('tab') ? Number(params.get('tab')) : 0;
      this.toggleTabs(this.openTab);
      this.openSection = params.has('section') ? params.get('section') : this.getFirstSectionId(0);
      this.toggleSections(this.openSection);
      this.lastSaved = new Date(this.model.changed).toLocaleString();
      this.setSectionMenuItems();
      this.assessmentStatus = this.model.assessmentStatus;
      this.isPendingPublish = this.model.isPendingPublish;
      this.isPublished = this.model.isPublished;
      this.contributors = this.model.schema.contributors;
      this.setMetaTags();
      this.getContactAddress();
    },
    methods: {
      getFirstSectionId(index) {
        if (this.model.schema.schemaItems !== null) {
          const items = this.model.schema.schemaItems.filter((item) => {
            if (item.showInLeftMenu && item.fieldGroup === index) {
              return {
                title: item.title,
                id: item.contentGuid,
              };
            }
            return false;
          });
          if (items && items.length > 0) {
            return items[0].contentGuid;
          }
        }
        return null;
      },
      setSectionMenuItems() {
        this.sectionMenuItems = this.model.schema.schemaItems.filter((item) => {
          if (item.showInLeftMenu && item.fieldGroup === this.openTab) {
            return {
              title: item.title,
              id: item.contentGuid,
            };
          }
          return false;
        });
      },
      onInputSave() {
        fetch(`${window.location.origin}/api/schema/GetLastSavedSchemaPage?id=${this.model.contentLink.guidValue}`).then((response) => {
          response.json().then((data) => {
            this.lastSaved = new Date(data).toLocaleString();
          });
        });
      },
      toggleTabs(index) {
        this.getSchema();
        this.openTab = index;
        this.openSection = this.getFirstSectionId(index);
        this.setSectionMenuItems();
        this.pushState();
      },
      toggleSections(id) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.getSchema();
        this.openSection = id;
        this.pushState();
      },
      pushState() {
        // Update URL
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?tab=${this.openTab}&section=${this.openSection}&role=${this.userRole}&id=${this.userId}`;
        window.history.pushState({ path: newurl }, '', newurl);
      },
      updateSchemeTitle(payload) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              id: this.model.contentLink.guidValue,
              type: 'heading',
              value: payload.text,
              }),
        };
        this.isSaving = true;
        const path = `${window.location.origin}/api/schema/SaveSchemaBaseProp`;
        fetch(path, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              payload.callback({
                success: true,
                message: null,
                details: null,
              });
              this.$toast.open({
                message: 'Tittel er oppdatert',
                type: 'success',
                dismissible: true,
              });
            } else {
              payload.callback({
                success: false,
                message: this.$t('schema.common.action.comment.errorSaving2'),
                details: `${response.status} ${response.statusText}`,
              });
            }
          });
      },
      getSchema() {
        const assessmentId = this.model.contentLink.id;
        const url = `${window.location.origin}/api/schema/GetMethodAssessmentDetails/${assessmentId}`;
        fetch(url).then((response) => {
          response.json().then((data) => {
            this.schema = data;
            this.assessmentStatus = data.assessmentStatus;
            this.isPendingPublish = data.isPendingPublish;
            this.isPublished = data.isPublished;
            this.isPeerReviewed = data.isPeerReviewed;
            this.hasPeerReviewsV2 = data.hasPeerReviewsV2;
            this.contributors = data.contributors;
          }).catch((error) => {
            console.log(error);
          });
        });
      },
      setupTabs() {
        this.peerReview = this.userRole === 'peer';
        this.tabs = this.model.schema.fieldGroups;
      },
      setMetaTags() {
        document.title = this.model.name;
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
        const noindexMetaTag = document.createElement('meta');
        noindexMetaTag.setAttribute('name', 'robots');
        noindexMetaTag.setAttribute('content', 'noindex');
        document.head.appendChild(noindexMetaTag);
      },
      getContactAddress() {
        const url = `${window.location.origin}/api/schema/GetContactAddress`;
        fetch(url).then((response) => {
          response.json().then((data) => {
            this.contactAddress = `mailto:${data}`;
          }).catch((error) => {
            console.log('error', error);
          });
        });
      },
    },
  };
</script>

<style>
  .tabwrapper {
    margin-left: 50%;
    margin-right: 50%;
    transform: translateX(-50%);
    width: 99vw;
  }
</style>
