<template>
  <div v-if="isPublished && model.hideWhenPublished" class="mb-8" >
    <div :class="preview ? 'w-full' : 'w-3/4'">
      <FieldTitle :model="model" :id="id" />
      <input type="text"
             class="nomargin"
             :id="guid"
             v-model="hiddenDataText"
             disabled />
    </div>
  </div>
  <div v-else class="mb-8">
    <div :class="preview ? 'w-full' : 'w-3/4'">
      <FieldTitle :model="model" :id="id" />
    </div>
    <div v-if="readOnly" class="flex">
      <div v-if="model.properties.isDateInput">
        <div v-html="readOnlyText" class="nomargin w-full border bg-graylight-100 p-4 rounded" />
      </div>
      <div v-else :class="preview ? 'w-full' : 'w-3/4'">
        <div v-if="readOnlyText === 'Ikke utfylt'"  v-html="readOnlyText" class="nomargin w-full border bg-graylight-100 text-gray-300 p-4 rounded italic" />
        <div v-else v-html="readOnlyText" class="nomargin w-full border bg-graylight-100 p-4 rounded break-words" />
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <div class="relative">
          <span v-show="!valid" class="text-red-300 italic mb-2">{{$t('schema.common.validation.mandatory')}}</span>
          <div v-if="multiline">
            <ResizableTextArea :name="guid"
                                class="h-full overflow-hidden break-words nomargin"
                                :placeholder="placeholder"
                                :readOnly="false"
                                v-on:change="save"
                                v-model="inputValue" />
          </div>
          <div v-else>
            <div v-if="model.properties.isDateInput" class="w-1/4">
              <input type="date"
                      class="nomargin"
                      :id="id"
                      placeholder="dd-mm-åååå"
                      min="01-01-2022"
                      v-model="inputValue"
                      :class="isSaving? 'isSaving': ''"
                      v-on:change="save" />
            </div>
            <div v-else>
              <input type="text"
                      class="nomargin"
                      :id="id"
                      :placeholder="placeholder"
                      v-model="inputValue"
                      :class="isSaving? 'isSaving': ''"
                      v-on:change="save"
                      v-on:click="validate" />
            </div>
          </div>
          <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-3" />
        </div>
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane v-if="!model.deactivateComments"
                      :model="model.comments"
                      :contentGuid="model.contentGuid"
                      :pageId="pageId"
                      :fieldTitle="model.title" />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';
  import ResizableTextArea from '@/views/components/textarea/ResizableTextArea.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
      isPublished: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      CommentPane,
      FieldTitle,
      ResizableTextArea,
    },
    data() {
      return {
        valid: true,
        guid: this.model.contentGuid,
        type: this.model.type,
        isSaving: false,
        inputValue: this.model && this.model.properties ? this.model.properties.text : '',
        placeholder: this.model && this.model.properties ? this.model.properties.placeholder : '',
        id: this.$uuid(),
        readOnlyText: this.model.properties.text ? this.model.properties.text : 'Ikke utfylt',
        hiddenDataText: 'Informasjonen er skjult',
      };
    },
    computed: {
      multiline() {
        return this.model.properties.multiline;
      },
    },
    methods: {
      validate() {
        if (this.model.mandatory && (this.inputValue === '' || this.inputValue === null)) {
          this.valid = false;
        }
      },
      save() {
        if (this.model.mandatory && this.inputValue === '') {
          this.valid = false;
        } else {
          this.valid = true;
        }
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid, value: this.inputValue, type: this.type, pageId: this.pageId,
          }),
        };
        this.isSaving = true;
        const self = this;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
            this.$emit('getSchema');
          }).then(() => {
            self.isSaving = false;
            this.$toast.open({
              message: 'Lagret',
              type: 'success',
              dismissible: true,
            });
          });
      },
    },
    emits: ['onSave', 'getSchema'],
  };
</script>
