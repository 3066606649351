<template>
  <div class="container">
    <Loading v-show="!assessment" />
    <!--Draft-->
    <div v-if="assessment != null">

      <div v-if="assessment.isDraft">
        <div class="w-full mb-6">
          <h1 class="mb-2 font-serif font-semibold text-hlg">{{assessment.heading}}</h1>
        </div>

        <!-- Toolbar -->
        <Toolbar v-if="assessment"
                 :model="assessment"
                 :showNotify="false"
                 :showSharing="true"
                 :showPrint="true"
                 :hideContact="assessment.hideContact"
                 class="border-b border-gray-100 pb-9 mb-9" />

        <div class="w-full pb-6 md:w-1/2" v-if="assessment.statusText">
          <p class="text-gray-300">{{$t('schema.schemaPage.status')}}</p>
          <p>{{assessment.statusText}}</p>
        </div>

        <!--Summary-->
        <section v-if="assessment.summary"
                 class="mb-9">
          <div v-html="assessment.summary"
               class="article-small"></div>
        </section>
        <!--Detail List-->
        <div class="w-full">
          <DetailList :subjects="assessment.relatedSubjects"
                      :topics="assessment.relatedTopics"
                      :organizations="assessment.relatedOrganizations"
                      :municipalities="assessment.relatedMunicipalities"
                      :methodTypes="assessment.relatedMethodTypes"
                      :published="assessment.year" />
        </div>
        <div v-show="assessment.approvedDisplayContact" class="w-full pb-6 md:w-1/2">
          <p class="pb-1 font-sans text-sm text-gray-300">{{$t('schema.schemaPage.contact')}}:</p>
          <!--TODO:GDPR-->
          <p v-if="assessment.approvedDisplayContact"><a :href="contactEmail" class="underline cursor-pointer text-lightblue-300">{{contactName}}</a></p>
        </div>

      </div>
      <!--Published-->
      <div v-else class="w-full">

        <!-- Header -->
        <div class="flex justify-end mb-0 md:mb-8">
          <div class="flex w-full xl:w-3/4">
            <div class="w-full xl:w-3/4">
              <h1 class="mb-6 font-serif font-semibold text-hmd md:text-hlg">
                {{ assessment.heading }}
              </h1>
              <ChangeLogSimple v-if="assessment"
                               class="mb-6"
                               :model="assessment" />
              <!--Toolbar-->
              <Toolbar v-if="assessment"
                       :model="assessment"
                       :showSharing="true"
                       :showPrintableVersionLink="true"
                       :hideContact="assessment.hideContact"
                       class="pb-4 mb-4" />
              <!-- Assessment as PDF -->
              <section v-if="assessment.schemaAsPdf" class="pb-8 mt-12 mb-8 border-b border-gray-100 print:hidden">
                <a :href="assessment.schemaAsPdf"
                   class="text-lg md:text-hxs text-lightblue-300 group"
                   :title="$t('schema.schemaPage.downloadMethodEvaluation')"
                   download>
                  <font-awesome-icon class="mr-4" icon="file-pdf" />
                  <span class="underline group-hover:no-underline">
                    {{ $t('schema.schemaPage.downloadMethodEvaluation') }}
                  </span>
                </a>
              </section>
              <!--Summary-->
              <section v-if="assessment.summary"
                       class="mb-9">
                <div v-html="assessment.summary"
                     class="article-small"></div>
              </section>
              <!--Detail List-->
              <div class="w-full">
                <DetailList class="mb-9"
                            :topics="assessment.relatedTopics"
                            :organizations="assessment.relatedOrganizations"
                            :methodTypes="assessment.relatedMethodTypes"
                            :subjects="assessment.relatedSubjects"
                            :municipalities="assessment.relatedMunicipalities"
                            :published="assessment.year" />
              </div>
            </div>
          </div>
        </div>
        <!-- End header-->

        <!-- Tabs -->
        <div class="hidden xl:block mt-8 border-b border-gray-100 tabwrapper mb-14">
          <div class="container flex justify-start xl:justify-end">
            <nav class="w-full pr-0 xl:w-3/4 lg:pr-4" aria-label="Faner">
              <ul class="flex w-full xl:w-3/4 -mb-px list-none">
                <li class="w-full"
                    v-for="(tab, index) in tabs"
                    :key="`${tab.value}`">
                  <button @click.prevent="toggleTabs(index)"
                          :class="openTab === index ? 'bg-bluegray-200' : ''"
                          class="block w-full pb-1 text-base text-black cursor-pointer rounded-t-md border border-bluegray-600 py-4 hover:border-red-300 hover:text-red-300 hover:border-b-4">
                    {{tab.text}}
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- End tabs -->

        <!-- Content -->
        <div v-if="assessmentSchema" class="flex">
          <aside class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
            <!-- menu-->
            <SectionMenu :sections="sectionMenuItems" :openSection="openSection" @toggleSections="toggleSections($event)" />
          </aside>
          <div class="w-full pr-0 xl:w-3/4 lg:pr-4">
            <div v-if="screenWidth < 1280">
              <div v-for="schemaItem in assessmentSchema.schemaItems" :key="schemaItem.contentGuid">
                <template v-if="schemaItem.blockType.toString().toLowerCase() === 'section' || schemaItem.blockType.toString().toLowerCase() === '0'">
                  <Section :model="schemaItem"
                           :readOnly="readOnly"
                           :preview="preview" />
                </template>
                <template v-if="schemaItem.blockType.toString().toLowerCase() === 'peerreviewsection' || schemaItem.blockType.toString().toLowerCase() === '1'">
                  <PeerReviewSection v-if="!hasPeerReviewsV2"
                                     :model="schemaItem"
                                     :readOnly="readOnly"
                                     :title="assessmentSchema.name"
                                     :organization="assessmentSchema.organization"
                                     :preview="preview"
                                     :isPeerReviewed="isPeerReviewed"
                                     :isPeerReview="false"
                                     :isPublished="true" />
                  <PeerReviewSectionV2 v-if="hasPeerReviewsV2"
                                       :model="model"
                                       :readOnly="readOnly"
                                       :reviews="assessmentSchema.peerReviews"
                                       :organization="assessmentSchema.organization"
                                       :isPublished="isPublished" />
                </template>
              </div>
            </div>
            <div v-else>
              <ul>
                <li v-for="schemaItem in assessmentSchema.schemaItems" :key="schemaItem.contentGuid">
                  <template v-if="schemaItem.fieldGroup === openTab && ( schemaItem.blockType.toString().toLowerCase() === 'section' || schemaItem.blockType.toString().toLowerCase() === '0' )">
                    <Section v-if="schemaItem.contentGuid === openSection"
                             :model="schemaItem"
                             :readOnly="readOnly"
                             :preview="preview"
                             :isPublished="true" />
                  </template>
                  <template v-if="schemaItem.fieldGroup === openTab &&
                          (schemaItem.blockType.toString().toLowerCase() === 'peerreviewsection' || schemaItem.blockType.toString().toLowerCase() === '1' )">
                    <PeerReviewSection v-if="schemaItem.contentGuid === openSection && !hasPeerReviewsV2"
                                       :model="schemaItem"
                                       :readOnly="readOnly"
                                       :title="assessmentSchema.name"
                                       :organization="assessmentSchema.organization"
                                       :preview="preview"
                                       :isPeerReviewed="isPeerReviewed"
                                       :isPublished="isPublished"
                                       :isPeerReview="true" />
                    <PeerReviewSectionV2 v-if="schemaItem.contentGuid === openSection && hasPeerReviewsV2"
                                         :model="model"
                                         :readOnly="readOnly"
                                         :reviews="assessmentSchema.peerreviews"
                                         :organization="assessmentSchema.organization"
                                         :isPublished="isPublished"
                                         :isPeerReviewed="isPeerReviewed" />
                  </template>
                </li>
              </ul>
              <SectionNavigation v-if="sectionMenuItems"
                                 :openSection="openSection"
                                 :sections="sectionMenuItems"
                                 @toggleSections="toggleSections($event)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ChangeLogSimple from '@/components/article/ChangeLogSimple.vue';
  import Toolbar from '@/views/components/toolbar/Toolbar.vue';
  import Section from '@/views/components/schema/Section.vue';
  import Loading from '@/views/components/Loading.vue';
  import PeerReviewSection from '@/views/components/schema/peerReview/PeerReviewSection.vue';
  import PeerReviewSectionV2 from '@/views/components/schema/peerReview/PeerReviewSectionV2.vue';
  import SectionMenu from '@/views/components/schema/SectionMenu.vue';
  import SectionNavigation from '@/views/components/schema/SectionNavigation.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isMobile: false,
        screenWidth: 0,
        assessment: null,
        assessmentSchema: null,
        readOnly: true,
        preview: false,
        isPublished: true,
        isPeerReviewed: false,
        hasPeerReviewsV2: false,
        contactEmail: '',
        contactName: '',
        openTab: 0,
        openSection: '',
        tabs: [],
        sectionMenuItems: [],
      };
    },
    components: {
      ChangeLogSimple,
      Toolbar,
      Section,
      Loading,
      PeerReviewSection,
      PeerReviewSectionV2,
      SectionMenu,
      SectionNavigation,
    },
    mounted() {
      this.getAssessment();
      this.updateScreenWidth();
      window.addEventListener('resize', this.updateScreenWidth);
    },
    methods: {
      getAssessment() {
        const assessmentId = this.$route.query.id;
        fetch(`${window.location.origin}/api/methodassessment/GetAssessment?id=${assessmentId}`).then((response) => {
          response.json().then((data) => {
            this.$store.commit('changeLastElementBreadcrumbs', { text: data.heading, url: '' });
            this.assessment = data;
            this.contactEmail = `mailto:${data.contactEmail}`;
            this.contactName = data.contactName;
            this.assessmentSchema = data.schema;
            this.isPublished = data.schema.isPublished;
            this.isPeerReviewed = data.schema.isPeerReviewed;
            this.hasPeerReviewsV2 = data.hasPeerReviewsV2;
            this.setupTabs();
            this.setSectionMenuItems();
            const params = new URLSearchParams(window.location.search);
            this.openTab = params.has('tab') ? Number(params.get('tab')) : 0;
            this.openSection = params.has('section') ? params.get('section') : this.getFirstSectionId(0);

            this.setMetaTags();
          });
        });
      },
      setMetaTags() {
        document.title = this.assessment.heading;
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.assessment.heading);
      },
      setupTabs() {
        this.tabs = this.assessmentSchema.fieldGroups;
      },
      toggleTabs(index) {
        this.openTab = index;
        this.openSection = this.getFirstSectionId(index);
        this.setSectionMenuItems();
        this.pushState();
      },
      toggleSections(id) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.openSection = id;
        this.pushState();
      },
      setSectionMenuItems() {
        this.sectionMenuItems = this.assessmentSchema.schemaItems.filter((item) => {
          if (!item.hideWhenPublished && item.showInLeftMenu && item.fieldGroup === this.openTab) {
            return {
              title: item.title,
              id: item.contentGuid,
            };
          }
          return false;
        });
        console.log(this.sectionMenuItems);
      },
      getFirstSectionId(index) {
        if (this.assessmentSchema.schemaItems !== null) {
          const items = this.assessmentSchema.schemaItems.filter((item) => {
            if (item.showInLeftMenu && item.fieldGroup === index) {
              return {
                title: item.title,
                id: item.contentGuid,
              };
            }
            return false;
          });
          return items[0].contentGuid;
        }
        return null;
      },
      pushState() {
        // Update URL
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?id=${this.$route.query.id}&tab=${this.openTab}&section=${this.openSection}`;
        window.history.pushState({ path: newurl }, '', newurl);
      },
      updateScreenWidth() {
        this.screenWidth = window.innerWidth;
      },
    },
  };
</script>
