<template>
  <FocusTrap :initial-focus="() => $refs.closePopup"
             :escapeDeactivates="true"
             @deactivate="onDeactivateFocusTrap()"
             ref="focusTrapHelpPopup">
    <div class="z-30 fixed w-full h-full top-0 left-0 flex items-center justify-center bg-gray-400 bg-opacity-50 mx-auto">
      <div v-click-outside="hidePopup" class="xl:w-1/4 lg:w-1/2 md:w-2/3 sm:w-full inset-0 my-4 p-8 max-h-screen overflow-y-scroll md:overflow-hidden bg-bluegray-200">

        <div class="flex justify-between h-6 mb-8">
          <h1 class="font-semibold text-xl flex-row pb-9">{{$t('schema.schemaPage.inviteContributors.contributors')}}</h1>
          <button @click="hidePopup" class="cursor-pointer">
            <font-awesome-icon class="font-semibold text-xl" icon="times" />
          </button>
        </div>

        <!-- Registrerte bidragsytere -->
        <div v-if="people && !addNew" class="overflow-y-auto min-h-max max-h-96 mb-4">
          <div v-for="(item, index) in people" class="flex flex-col border rounded bg-white p-4 mb-2" :key="index">
            <div class="flex w-full">
              <div class="w-1/2">
                <span class="font-semibold text-lg pb-1">{{item.name}}</span>
              </div>
              <div v-if="item.isDeletable" class="w-1/2">
                <button @click="openPopUp(item)" class="float-right">
                  <font-awesome-icon icon="trash" />
                </button>
              </div>
            </div>
            <span>
              <font-awesome-icon class="pr-2 text-xl text-bluegray-400" icon="user" />
              {{getRoleDisplay(item.role)}}
            </span>
            <span>
              <font-awesome-icon class="pr-2 text-xl text-bluegray-400" icon="building" />
              {{item.organization}}
            </span>
            <span>
              <font-awesome-icon class="pr-2 text-xl text-bluegray-400" icon="envelope" />
              {{item.email}}
            </span>
          </div>
        </div>

        <!-- Invitere bidragsytere -->
        <div v-if="addNew">
          <form @submit.prevent="sendInvitation">
            <div v-show="this.loading">
              <Loading :small="true"/>
            </div>
            <div class="flex flex-col justify-start mb-2">

              <label class="text-sm" for="name">{{$t('schema.schemaPage.inviteContributors.labels.name')}}</label>
              <input type="text" id="name" v-model="invite.name" required :placeholder="$t('schema.schemaPage.inviteContributors.placeholders.name')" />

              <label class="text-sm" for="email">{{$t('schema.schemaPage.inviteContributors.labels.email')}}</label>
              <input type="email" id="email" v-model="invite.email" required :placeholder="$t('schema.schemaPage.inviteContributors.placeholders.email')" />

              <!--Valg for helseforetak eller fylke/kommune (for samhandling)-->
              <div v-if="assessmentType === '3'" class="flex flex-row gap-4 mb-4">
                <div>
                  <label class="text-sm mr-2" for="rb_organization">Helseforetak</label>
                  <input type="radio" value="organization" id="rb_organization" name="actor_type" v-model="selectedActor" @change="onActorChange">
                </div>
                <div>
                  <label class="text-sm mr-2" for="rb_county">Fylke/kommune</label>
                  <input type="radio" value="county" id="rb_county" name="actor_type" v-model="selectedActor" @change="onActorChange">
                </div>
                <div>
                  <label class="text-sm mr-2" for="rb_otherOrganization">Annen</label>
                  <input type="radio" value="otherOrganization" id="rb_otherOrganization" name="actor_type" v-model="selectedActor" @change="onActorChange">
                </div>
              </div>

              <div v-if="selectedActor || assessmentType === '1' || assessmentType === '2'">
                <!-- Helseforetak (for samhandling og spesialist) -->
                <div v-if="selectedActor === 'organization' || assessmentType === '1'">
                  <label class="text-sm" for="organization">{{$t('schema.schemaPage.inviteContributors.labels.organization')}}</label>
                  <select v-model="invite.organization">
                    <option value="">{{$t('schema.introPage.organization.optionDescription')}}</option>
                    <option v-for="(option, index) in organizationOptions" :key="$uuid(index)" v-bind:value="option.text">
                      {{option.text}}
                    </option>
                  </select>
                </div>
                <!-- Fylke og kommune (for samhandling og kommune) -->
                <div v-if="selectedActor === 'county' || assessmentType === '2'">
                  <label class="text-sm" for="organization">{{$t('schema.schemaPage.inviteContributors.labels.municipality')}}</label>
                  <select v-model="invite.organization">
                    <option value="">{{$t('schema.introPage.municipality.optionDescription')}}</option>
                    <optgroup :label="option.text" v-for="(option, oindex) in municipalityOptions" :key="$uuid(oindex)">
                      <option v-for="(subItem, sindex) in option.subItems" :key="$uuid(sindex)" :value="subItem.text">{{subItem.text}}</option>
                    </optgroup>
                  </select>
                </div>
                  <!-- Annen tilhørighet -->
                  <div v-if="selectedActor === 'otherOrganization'">
                    <label class="text-sm" for="other">{{$t('schema.schemaPage.inviteContributors.labels.other')}}</label>
                    <input type="text" id="other" v-model="invite.other" :placeholder="$t('schema.schemaPage.inviteContributors.placeholders.other')" />
                  </div>
              </div>

              <label class="text-sm" for="role">{{$t('schema.schemaPage.inviteContributors.labels.role')}}</label>
              <select v-model="invite.role" @change="onRoleSelectedChange($event)">
                <option value="">{{$t('schema.common.action.pleaseSelect')}}</option>
                <option v-for="(option, index) in roles" :key="$uuid(index)" v-bind:value="option.roleValue">
                  {{option.roleDisplay}}
                </option>
              </select>

              <button type="submit"
                      class="btn btn--full"
                      :title="$t('schema.schemaPage.inviteContributors.sendInvitasion')"
                      :class="this.loading ? 'btn--disabled' : 'btn--charcoalgray'"
                      :disabled="this.loading">
                <font-awesome-icon icon="envelope" />
                {{$t('schema.schemaPage.inviteContributors.sendInvitasion')}}
              </button>
            </div>
          </form>
        </div>

        <!-- Knapper -->
        <div v-if="!addNew" class="flex justify-start mb-2">
          <button class="btn btn--full btn--charcoalgray"
                  :title="$t('schema.schemaPage.inviteContributors.inviteContributor')"
                  @click="addContributor">
              <font-awesome-icon icon="plus" />
              {{ $t('schema.schemaPage.inviteContributors.inviteContributor')}}
          </button>
        </div>
        <div class="flex justify-start">
          <button class="btn btn--full btn--secondary__outline"
                  @click="hidePopup"
                  :title="$t('window.close.description.popup')"
                  ref="closePopup">
            {{$t('window.close.text')}}
          </button>
        </div>

        <div v-if="showPopUp">
          <ConfirmPopUp @close="closePopUp" @confirm="confirm" :text="'Ønsker du å slette bidragsyteren?'" />
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
  import vClickOutside from 'click-outside-vue3';
  import { FocusTrap } from 'focus-trap-vue';
  import { HIDE_MODAL } from '@/store/modules/appContext';
  import ConfirmPopUp from '@/views/components/confirm/ConfirmPopUp.vue';
  import Loading from '@/views/components/Loading.vue';

  export default {
    props: {
      pageId: {
        type: String,
        required: false,
      },
      roles: {
        type: Object,
        required: false,
      },
      contributors: {
        type: Object,
        required: false,
      },
      organizations: {
        type: Object,
        required: false,
      },
      organizationOptions: {
        type: Object,
        required: false,
      },
      municipalityOptions: {
        type: Object,
        required: false,
      },
      assessmentType: {
        type: String,
        required: false,
      },
    },
    components: {
      FocusTrap,
      ConfirmPopUp,
      Loading,
    },
    data() {
      return {
        deleteItem: {},
        showPopUp: false,
        people: [],
        addNew: false,
        selectedActor: null,
        loading: false,
        invite: {
          name: '',
          organization: '',
          other: '',
          email: '',
          role: '',
          isDeletable: true,
          contributorId: 0,
        },
      };
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    methods: {
      onActorChange() {
        this.invite.organization = '';
        this.invite.other = '';
      },
      openPopUp(item) {
        this.deleteItem = item;
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
      confirm() {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.pageId,
            name: this.deleteItem.name,
            email: this.deleteItem.email,
            organization: this.deleteItem.organization,
            role: this.deleteItem.role,
            isDeletable: this.deleteItem.isDeletable,
            contributorId: this.deleteItem.contributorId,
          }),
        };
        fetch(`${window.location.origin}/api/schema/DeleteContributor`, requestOptions)
          .then(() => {
            this.$emit('update');
            this.people = this.people.filter((value) => value.contributorId !== this.deleteItem.contributorId);
            this.$toast.open({
              message: 'Bidragsyteren er slettet',
              type: 'success',
              dismissible: true,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      onRoleSelectedChange(event) {
        this.invite.role = event.target.value;
      },
      getRoleDisplay(value) {
        const index = this.roles.findIndex((x) => x.roleValue === value);
        return this.roles[index]?.roleDisplay;
      },
      hidePopup() {
        this.$emit('close');
        this.$store.commit(HIDE_MODAL);
      },
      onDeactivateFocusTrap() {
        this.hidePopup();
      },
      addContributor() {
        this.addNew = true;
      },
      sendInvitation() {
        this.loading = true;
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.pageId,
            name: this.invite.name,
            email: this.invite.email,
            role: this.invite.role,
            isDeletable: this.invite.isDeletable,
            contributorId: this.invite.contributorId,
            organization: this.invite.organization === 'other' ? this.invite.other : this.invite.organization,
          }),
        };
        fetch(`${window.location.origin}/api/schema/InviteContributor`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.$emit('update');
            this.addNew = false;
            this.loading = false;
            this.people.push({
              name: data.name,
              email: data.email,
              organization: data.organization,
              role: data.role,
              isDeletable: data.isDeletable,
              contributorId: data.contributorId,
            });
            this.invite.name = '';
            this.invite.email = '';
            this.invite.organization = '';
            this.invite.other = '';
            this.invite.role = '';
          });
      },
    },
    mounted() {
      this.people = this.contributors;
    },
    emits: ['update'],
  };
</script>
