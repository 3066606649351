<template>
  <div class="border-2 my-2 p-4 rounded">
    <div class="w-full flex" :aria-controls="`collapse${id}`">
      <slot name="title" />
      <div class="flex flex-row float-right">
        <button v-show="!readOnly && !hideDelete" @click="deleteItem()" class="mr-6">
          <font-awesome-icon class="float-right" icon="trash" />
        </button>
        <button v-if="!preview" @click="toggleAccordion()">
          <font-awesome-icon v-if="!isOpen" class="float-right" icon="chevron-down" />
          <font-awesome-icon v-if="isOpen" class="float-right" icon="chevron-up" />
        </button>
      </div>
    </div>
    <div v-if="isOpen || preview" :id="`collapse${id}`">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
      id: {
        type: String,
        required: true,
      },
      hideDelete: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
      deleteItem() {
        this.$emit('deleteItem');
      },
    },
    mounted() {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_uid"] }] */
      // this.id = this._uid;
    },
  };
</script>
