<template>
  <div v-if="readOnly">
    <div :class="preview ? 'w-full' : 'w-3/4'">
      <div v-if="items.length === 0">
        <div class="bg-orange-100 rounded py-2 px-4 opacity-80">
          {{$t('schema.literatureSearch.label.noSearches')}}
        </div>
      </div>
      <div v-else>
        <ul>
          <li v-for="(item, index) in items" :key="$uuid(index)">
            <LiteratureSearchAccordion :model="item" :readOnly="readOnly" :preview="preview"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="flex" :id="id">
    <div class="w-full pr-0 xl:w-3/4">
      <div v-if="items.length === 0">
        <div v-show="!isSaving" class="bg-orange-100 rounded py-2 px-4 opacity-80 mb-4">
          {{$t('schema.literatureSearch.label.noSearches')}}
        </div>
        <ul>
          <li>
            <div v-show="isSaving" class="w-full flex justify-center items-center">
              <font-awesome-icon icon="spinner" class="animate-spin text-charcoalgray text-hlg" />
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <ul>
          <li v-for="(item, index) in items" :key="$uuid(index)">
            <LiteratureSearchAccordion :model="item" :readOnly="readOnly" @get-schema="getSchema" />
          </li>
          <li>
            <div v-show="isSaving" class="w-full flex justify-center items-center">
              <font-awesome-icon icon="spinner" class="animate-spin text-charcoalgray text-hlg" />
            </div>
          </li>
        </ul>
      </div>
      <div v-if="showNew" class="p-4 mb-4 border-2 bg-bluegray-300">
        <form @submit.prevent="save">
          <label class="text-sm" for="source">{{$t('schema.literatureSearch.label.selectSource')}}:</label>
          <select v-model="newItem.selected" @change="onSelectedChange($event)" ref="literatureSearchSourceDropdown">
            <option disabled value="">{{$t('schema.common.action.pleaseSelect')}}</option>
            <optgroup :label="$t('schema.literatureSearch.label.optgrp_database')">
              <option v-for="(option, index) in dbSources"
                      :key="$uuid(index)" v-bind:value="JSON.stringify(option)">
                {{option.text}}
              </option>
              <option value="OtherDb">{{$t('schema.literatureSearch.label.otherDbSource')}}</option>
            </optgroup>
            <optgroup :label="$t('schema.literatureSearch.label.optgrp_sources')">
              <option v-for="(option, index) in netSources"
                      :key="$uuid(index)" v-bind:value="JSON.stringify(option)">
                {{option.text}}
              </option>
              <option value="OtherWeb">{{$t('schema.literatureSearch.label.otherWebSource')}}</option>
            </optgroup>
          </select>
          <div v-if="newItem.searchType === 'DB' || newItem.searchType === 'Web'">
            <label class="text-sm" for="source">{{$t('schema.literatureSearch.label.source')}}:</label>
            <input type="text" id="source" v-model="newItem.source" required />
          </div>
          <div v-if="newItem.searchType === 'Web'">
            <label class="text-sm" for="link">{{$t('schema.literatureSearch.label.sourceLink')}}:</label>
            <input type="text" id="link" v-model="newItem.url" />
          </div>
          <div v-if="newItem.searchType === 'DB' || newItem.searchType === 'Web'">
            <label class="text-sm" for="strategy">{{$t('schema.literatureSearch.label.searchStrategy')}}:</label>
            <Editor :api-key="getTinyKey" :init="getTinyConfig" v-model="newItem.strategy"/>
          </div>
          <div v-if="newItem.searchType === 'DB'" class="lg:w-1/4 sm:w-1/2">
            <label class="text-sm" for="hits">{{$t('schema.literatureSearch.label.numberOfHits')}}:</label>
            <input type="number" id="hits" v-model="newItem.numberOfSearchHits" required />
          </div>
          <div v-if="newItem.searchType === 'DB' || newItem.searchType === 'Web'" class="lg:w-1/4 sm:w-1/2">
            <label class="text-sm" for="date">{{$t('schema.common.time.date')}}:</label>
            <input type="date" id="date" placeholder="dd-mm-åååå" min="01-01-2022" v-model="newItem.date" required />
          </div>
          <div class="flex">
            <button v-show="newItem.searchType === 'DB' || newItem.searchType === 'Web'" type="submit" class="mr-4 btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="save" class="mr-2" />
              {{$t('schema.common.action.save')}}
            </button>
            <button type="reset" @click.prevent="cancel" class="btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="ban" class="mr-2" />
              {{$t('schema.common.action.cancel')}}
            </button>
          </div>
        </form>
      </div>
      <button v-if="!showNew" @click.prevent="addNew" class="btn btn--full btn--secondary__outline" ref="literatureSearchAddNew">
        <font-awesome-icon icon="plus" class="mr-2" />
        {{$t('schema.literatureSearch.addNew')}}
      </button>
    </div>
  </div>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import TinyConfig from '@/assets/scripts/tinyConfig';
  import LiteratureSearchAccordion from '@/views/components/schema/literatureSearch/LiteratureSearchAccordion.vue';

  export default {
    props: {
      items: {
        type: Object,
        required: false,
      },
      dbSources: {
        type: Object,
        required: false,
      },
      netSources: {
        type: Object,
        required: false,
      },
      // A, B, C, D or E
      section: {
        type: String,
        required: false,
      },
      contentId: {
        type: Number,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      LiteratureSearchAccordion,
      Editor,
    },
    computed: {
      getTinyConfig() {
        return TinyConfig.getConfig(this.type);
      },
      getTinyKey() {
        return TinyConfig.getAPIKey();
      },
    },
    data() {
      return {
        id: this.$uuid(),
        showNew: false,
        isSaving: false,
        newItem: {
          searchType: '',
          source: '',
          url: '',
          strategy: '',
          numberOfSearchHits: 0,
          date: '',
          selected: '',
          editableSource: false,
        },
        comments: null,
        contentGuid: '',
      };
    },
    methods: {
      goto() {
        const el = document.getElementById(this.id);
        el.scrollIntoView({ behavior: 'smooth' });
      },
      getSchema() {
        this.$emit('getSchema');
      },
      onSelectedChange(event) {
        this.newItem.source = '';
        this.newItem.url = '';
        this.newItem.searchType = '';

        if (event.target.value === 'OtherDb') {
          this.newItem.searchType = 'DB';
          this.newItem.editableSource = true;
        } else if (event.target.value === 'OtherWeb') {
          this.newItem.searchType = 'Web';
          this.newItem.editableSource = true;
        } else {
          const selectedOption = JSON.parse(event.target.value);
          this.newItem.source = selectedOption.text;
          if (selectedOption.type === 'Web') {
            this.newItem.url = selectedOption.value;
          } else {
            this.newItem.url = '';
          }
          this.newItem.searchType = selectedOption.type;
        }
      },
      save() {
        this.isSaving = true;

        const htmlEncodedStrategy = this.htmlEncode(this.newItem.strategy);

        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.contentId,
            section: this.section,
            searchType: this.newItem.searchType,
            source: this.newItem.source,
            url: this.newItem.url,
            date: new Date(this.newItem.date),
            strategy: htmlEncodedStrategy,
            numberOfSearchHits: this.newItem.numberOfSearchHits,
            editableSource: this.newItem.editableSource,
          }),
        };

        fetch('/api/schema/CreateLiteratureSearchItem', requestOptions)
          .then((response) => {
            if (response.ok) {
              this.showNew = false;
              this.clear();
              setTimeout(() => {
                this.goto();
                this.$emit('getSchema');
                this.$nextTick(() => {
                  this.$refs.literatureSearchAddNew.focus();
                  this.isSaving = false;
                  this.$toast.open({
                    message: 'Litteratursøk lagret',
                    type: 'success',
                    dismissible: true,
                  });
                });
              }, 500);
            } else {
              setTimeout(() => {
                this.$nextTick(() => {
                  this.isSaving = false;
                  this.$toast.open({
                    message: 'Litteratursøk ikke lagret',
                    type: 'error',
                    dismissible: true,
                  });
                });
              }, 500);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit('getSchema');
          });
      },
      addNew() {
        this.showNew = true;
        this.clear();
        this.$nextTick(() => {
          this.$refs.literatureSearchSourceDropdown.focus();
        });
      },
      cancel() {
        this.showNew = false;
        this.clear();
        this.$nextTick(() => {
          this.$refs.literatureSearchAddNew.focus();
        });
      },
      clear() {
        const d = new Date();
        // eslint-disable-next-line prefer-template
        const day = ('0' + d.getDate()).slice(-2);
        // eslint-disable-next-line prefer-template
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const year = d.getFullYear();
        const formatedDate = `${year}-${month}-${day}`;
        this.newItem.selected = '';
        this.newItem.searchType = '';
        this.newItem.source = '';
        this.newItem.url = '';
        this.newItem.date = formatedDate;
        this.newItem.strategy = '';
        this.newItem.numberOfSearchHits = 0;
        this.newItem.editableSource = false;
      },
      htmlEncode(str) {
        const div = document.createElement('div');
        div.appendChild(document.createTextNode(str));
        return div.innerHTML;
      },
    },
    emits: ['getSchema'],
  };
</script>
